/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { MaybeRef } from 'vue'
import { computed, toValue } from 'vue'
import type { Route } from '~/src/Application/Shared/Router/RouterInterface'
import type Engagement from '~/src/Domain/Engagement/Engagement'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import useNavigation from '~/src/UserInterface/App/composables/Navigation/useNavigation'

export default function useEngagementSettingsNavigation(
  organisation: MaybeRef<Organisation>,
  engagement: MaybeRef<Engagement>,
) {
  const { getLocalePath, navigateTo, uuidEncoder } = useNavigation()

  const params = computed(() => ({
    organisationId: uuidEncoder.encode(toValue(organisation)['@id'].toId()),
    organisationSlug: toValue(organisation).getSlug().toString(),
    engagementId: uuidEncoder.encode(toValue(engagement)['@id'].toId()),
    engagementSlug: toValue(engagement).getSlug().toString(),
  }))

  const getEngagementSettingsLocalePath = (
    route: Route,
  ) => {
    if (typeof route === 'string') {
      return getLocalePath({
        name: route,
        params: params.value,
      })
    }

    const routeParams = route.params ?? {}
    for (const param of ['phaseId', 'phaseSlug']) {
      if (param in routeParams) {
        delete routeParams[param]
      }
    }

    return getLocalePath(route)
  }

  const engagementSettingsGeneralLocalePath = computed(() =>
    getEngagementSettingsLocalePath('settings-engagement-General'),
  )
  const engagementSettingsAuditTeamLocalePath = computed(() =>
    getEngagementSettingsLocalePath('settings-engagement-AuditTeam'),
  )
  const engagementSettingsDataRequestsLocalePath = computed(() =>
    getEngagementSettingsLocalePath('settings-engagement-DataRequest'),
  )
  const engagementSettingsDataRequestAddLocalePath = computed(() =>
    getEngagementSettingsLocalePath('settings-engagement-DataRequestAdd'),
  )
  const engagementSettingsCoaMappingsLocalePath = computed(() =>
    getEngagementSettingsLocalePath('settings-engagement-CoaMapping'),
  )
  const engagementSettingsDataValidationLocalePath = computed(() =>
    getEngagementSettingsLocalePath('settings-engagement-DataValidation'),
  )

  const navigateToEngagementSettingsLocalePath = async (route: Route) => {
    await navigateTo(getEngagementSettingsLocalePath(route))
  }

  const engagementSettingsDataRequestAddSuccessPath = async () => {
    await navigateTo(getEngagementSettingsLocalePath('settings-engagement-DataRequestAddSuccess'))
  }

  return {
    getEngagementSettingsLocalePath,
    navigateToEngagementSettingsLocalePath,
    engagementSettingsDataRequestAddSuccessPath,
    engagementSettingsGeneralLocalePath,
    engagementSettingsAuditTeamLocalePath,
    engagementSettingsDataRequestsLocalePath,
    engagementSettingsDataRequestAddLocalePath,
    engagementSettingsCoaMappingsLocalePath,
    engagementSettingsDataValidationLocalePath,
  }
}
